import React from 'react';
function BugdetPage() {
    return ( <>
            <div className='container'>
                <h5>Budget </h5> 
                <br /> 
                
            </div>
    </> );
}

export default BugdetPage;