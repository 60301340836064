import React from 'react';
function DateProchPage() {
    return ( <>
            <div className='container'>
                <h5>Date Proche </h5> 
                <br /> 
                
            </div>
    </> );
}

export default DateProchPage;